@import "prismjs/themes/prism.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  box-sizing: border-box;
}

div.post-container span {
  color: #007bff;
}

div.not-found {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.index-promo {
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 0;
}

div.index-promo span {
  font-size: 22px;
}

.navbar-toggle {
  display: none;
}

.navbar-toggle span {
  font-size: 22px;
}

.navbar-toggle a {
  text-decoration: none;
}

div.index-promo a {
  text-decoration: none;
  color: #f1f1f1;
  font-size: medium;
  padding: 8px;
  margin: 5px;
  border-radius: 10px;
}

div.index-promo p:hover {
  text-decoration: underline;
}

.navbar {
  display: none;
}

/*--------------------------------------------------------------------- Mobile styles ------------------------------------------------------------------------*/
@media (max-width: 1260px) {
  div.navbar-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    backdrop-filter: blur(5.4px);
    -webkit-backdrop-filter: blur(5.4px);
    color: #f1f1f1;
    margin: 0;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .title {
    display: none;
  }

  .logout {
    display: none;
  }

  .navbar {
    display: block;
  }

  div.navbar-toggle h2 {
    font-weight: 500;
    margin: 0;
    padding: 0;
  }

  .navbar-btn {
    width: auto;
    display: inline-block;
    background: none;
    border: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    font-weight: normal;
    color: inherit;
    cursor: pointer;
    box-shadow: none;
    font-size: large;
    font-weight: bold;
  }

  .navbar-btn:hover {
    box-shadow: none;
  }

  div.index-promo a {
    padding: 5px;
    margin: 2px;
    font-size: small;
  }
}

.material-symbols-outlined {
  font-size: large;
  color: #ffffff;
}

div.home-index h1 {
  margin: 0;
  padding: 0;
  padding-bottom: 5px;
}

div.content a {
  color: #0969bd;
}

main {
  flex: 1;
}

body {
  max-height: max-content;
  color: #d5d5d5;
  background: rgb(236, 236, 236);
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  margin: 0;
}

.banner-img {
  max-width: 100%;
  height: 100%;
}

.icon {
  width: 40px;
  border-radius: 5px;
}

/*-------------------------------------------------------------------------Header component----------------------------------------------------------------*/
header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  padding-left: 15px;
  align-items: center;
  background: #004e92;
  width: 100%;
  color: #ffffff;
}

div.navbar {
  margin: 0;
  padding: 0;
  background: #004e92;
}

header a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 700px) {
  .web-title {
    display: none;
  }

  .header {
    width: 100%;
  }
}

div.logo {
  font-weight: bold;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  display: flex;
  gap: 5px;
}

/*---------------------------------------------------------------------Post page component-------------------------------------------------------------------*/
div.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  padding: 16px;
}

div.grid-item {
  backdrop-filter: blur(5.4px);
  -webkit-backdrop-filter: blur(5.4px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.212);
}

@media screen and (min-width: 700px) {
  div.post {
    grid-template-columns: 0.9fr 1.1fr;
  }
}

.post-page-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  width: 100%;
  gap: 10px;
}

.post-content {
  flex: 0 0 50%;
  padding: 10px;
  max-height: max-content;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
}

.post-content span {
  color: #007bff;
}

.side-container {
  flex: 0 0 25%;
  max-width: 400px;
}

.related-posts {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  color: #000000;
  padding: 10px;
  max-height: max-content;
}

@media (max-width: 1260px) {
  .post-page-container {
    flex-direction: column;
    gap: 15px;
  }

  .post-content,
  .related-posts {
    width: 100%;
  }

  .related-posts img {
    width: 100%;
  }
}

div.post div.text h2 {
  margin: 0;
  text-decoration: none;
  font-size: 1.4rem;
  padding-left: 10px;
  padding-top: 5px;
  color: #000000;
  padding-right: 5px;
}

div.post div.text a {
  text-decoration: none;
  color: inherit;
}

div.post div.text a:hover {
  text-decoration: underline;
}

div.post p.info {
  margin: 6px 0;
  font-size: small;
  font-weight: 500;
  padding-left: 10px;
}

div.post p.info a.author {
  margin: 6px 0;
  color: #007bff;
  font-weight: 500;
  margin-right: 5px;
}

div.post p.summary a {
  color: #a1a1a1;
  font-weight: 500;
}

div.post p.summary {
  margin: 6px 0;
  line-height: 1.4rem;
  font-size: 15px;
  font-weight: 600;
  color: #1b1b1bc5;
  padding-left: 10px;
  padding-right: 5px;
}

/*------------------------------------------------------------------------form component css----------------------------------------------------------------*/
form.login h1,
form.register h1 {
  text-align: center;
}

form.login,
form.register,
form.contact {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 50px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  color: #000000;
}

form.login a {
  color: #007bff;
  text-decoration: none;
  margin-top: 5px;
  font-size: small;
}

form.login button {
  margin-top: 5px;
}

form.login h4 {
  margin-bottom: 5px;
}

/*--------------------------------------------------------------------------contact form--------------------------------------------------------------------------------*/
form.feedback {
  padding: 10px;
  max-height: max-content;
  color: #000000;
  margin-top: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
}

form.feedback span,
form.contact span,
form.login span,
form.register span,
form.report span {
  color: #007bff;
}

form.contact button {
  margin-top: 8px;
}

form.contact h1 {
  text-align: center;
}

.contact-div {
  margin: 0;
  text-align: left;
  width: 100%;
  padding-top: 25px;
}

.contact-div h3,
.contact-div p {
  margin: 0;
  color: #000000;
}

.contact-div a {
  color: #007bff;
  text-decoration: none;
}

/*-----------------------------------------------------------------form report---------------------------------------------------------------------------*/

form.report {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
  color: #000000;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
}

form.report h1 {
  text-align: center;
}

/* ----------------------------------------------------------------------input buttons----------------------------------------------------------------------*/
h4 {
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding-top: 15px;
  padding-bottom: 5px;
}

/*--------------------------------------------------------------------------------------dropdown---------------------------------------------------------------*/

div.post-basic-info-box {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

img {
  max-width: 100%;
  height: 100%;
}

div.image {
  overflow: hidden;
  object-fit: cover;
  object-position: center;
  max-height: 400px;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  overflow: hidden;
}

div.post-content h2 {
  text-align: left;
  margin: 0 0 5px;
  color: #000000;
}

.post-content p,
p {
  font-weight: 500;
  color: #000000de;
  line-height: 1.6rem;
  font-size: 15px;
}

.post-content i {
  width: 100%;
}

.report-div {
  text-align: left;
  margin-top: 25px;
}

.report-div h3,
.report-div p {
  margin: 0;
  padding-bottom: 8px;
}

.report-div a {
  color: #98bdf7;
  text-decoration: none;
}

li {
  font-weight: 500;
  color: #c7c7c7;
  line-height: 1.6rem;
}

div.author {
  color: #ffffff;
  font-size: small;
}

div.hone-index {
  padding-left: 5px;
}

/*----------------------------------------------------------------------------footer----------------------------------------------------------------------------*/

footer {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.top-footer {
  background: #242424;
  padding: 10px;
}

.bottom-footer {
  background: #6e6e6e;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.footer-div {
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}

.footer-div h2 {
  background: linear-gradient(to right, #00eaff, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.footer-div p {
  color: rgb(255, 255, 255);
}

.footer-route {
  display: flex;
  gap: 10px;
  margin: 10px;
}

.footer-menu {
  display: flex;
  list-style: none;
  gap: 15px;
  font-weight: 200;
  margin: 0;
  padding-left: 25px;
}

.footer-menu a {
  text-decoration: none;
  font-size: small;
  color: #dcdcdc;
}

/*---------------------------------------------------------------------------editor toolbar ----------------------------------------------------------------*/

.quill-wrapper {
  margin-top: 10px;
  max-height: 600px;
  overflow-y: scroll;
}

.quill-wrapper::-webkit-scrollbar {
  display: none;
}

.quill-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ql-toolbar {
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: none;
  padding: 5px;
  box-sizing: border-box;
  background: white;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.ql-toolbar button {
  margin: 5px;
  color: #ffffff;
  margin: 5px;
  border-radius: 5px;
}

.ql-editor {
  min-height: 200px;
  border-radius: 5px;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.ql-editor p a {
  color: #007bff;
  text-decoration: none !important;
}

.ql-editor li,
div.content li {
  font-weight: 500;
  color: #000000de;
  line-height: 1.6rem;
  font-size: 15px;
}

div.content p a {
  color: #007bff;
  text-decoration: none;
}

div.content a:hover {
  text-decoration: underline;
}

div.content strong {
  font-size: large;
  font-weight: 800;
}

.ql-editor p u {
  font-weight: 800;
  text-decoration: none !important;
}

div.content p u {
  font-weight: 800;
  text-decoration: none !important;
}

div.content li u {
  font-weight: 800;
  text-decoration: none !important;
}

/*-----------------------------------------------------------------------post data section-----------------------------------------------------------------*/
div.post-data-header {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0;
  justify-content: left;
  margin-left: 12px;
}

div.post-data-header p {
  margin: 0;
  padding: 0;
  padding: 5px;
  align-items: center;
}

div.post-data-header button {
  padding: 5px;
  width: max-content;
  align-items: center;
  font-size: small;
  font-weight: 400;
}

.profile-btn {
  width: auto;
  display: inline-block;
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
  margin: 0;
  font-weight: normal;
  color: inherit;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  font-size: larger;
}

div.post-container {
  display: flex;
  align-items: center;
  margin: 10px;
  text-align: left;
  gap: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5.4px);
  -webkit-backdrop-filter: blur(5.4px);
  border-radius: 5px;
  max-width: 100%;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.no-post-ack {
  justify-content: center;
  text-align: center;
  padding-top: 10px;
}

.no-post-ack p {
  margin: auto;
}

.create-post-ack {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}

.create-post-ack a {
  text-decoration: none;
  font-weight: 500;
  color: #000000;
  line-height: 1.6rem;
}

.user-icons {
  margin-left: auto;
  display: flex;
}

.user-icons .material-symbols-outlined {
  margin: 0;
  padding: 5px;
}

.post-title {
  margin: 1px;
  font-size: small;
}
.post-title:hover {
  text-decoration: underline;
}

div.post-container img {
  width: 90px;
  height: 50px;
  margin: 5px;
  border-radius: 5px;
  align-items: flex-start;
  align-self: baseline;
}

div.related {
  width: 100%;
  margin-top: 10px;
}

hr {
  border-radius: 5px;
  margin-top: 0;
}

div.related h4 {
  margin: 0;
  padding: 0;
}

div.gicon-powered p {
  font-weight: 400;
  font-size: small;
}

div.gicon-title p {
  color: white;
}

div.gicon-powered img,
p,
div.gicon-title h1 {
  margin: 0;
  padding-bottom: 5px;
  padding: 0;
}

div.gicon-title,
div.gicon-title-main {
  display: flex;
  align-items: center;
  gap: 5px;
}

div.gicon-title-main p {
  color: #242424;
}
/*------------------------------------------------About---------------------------*/

div.about {
  padding: 30px;
  color: #000000;
  width: 40%;
  margin: 0 auto;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
}

div.about p {
  margin-top: 5px;
  text-align: left;
}

@media (max-width: 1120px) {
  div.about {
    width: 400px;
  }
}

/*------------------------------------------------Explore---------------------------*/
div.explore-main {
  margin-top: 10px;
  width: 100%;
}

.flex-div {
  display: flex;
  align-items: center;
}

.search-box {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5.4px);
  -webkit-backdrop-filter: blur(5.4px);
  border-radius: 25px;
  padding: 1px 10px;
  margin-top: 10px;
}

.search-box input {
  border: 0;
  outline: 0;
  background: transparent;
  box-shadow: none;
}

.search-box input:hover {
  box-shadow: none;
}

.search-box img {
  width: 20px;
}

.search-box-button {
  background: transparent;
  max-width: fit-content;
}

.search-box-button:hover {
  box-shadow: none;
}

div.explore-container {
  display: flex;
  width: 100%;
  gap: 7px;
  justify-content: space-evenly;
  overflow-x: auto;
  overflow-y: hidden;
  flex-direction: row;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5.4px);
  -webkit-backdrop-filter: blur(5.4px);
  -ms-overflow-style: none;
  scrollbar-width: none;
}
div.explore-container span {
  color: #007bff;
}

div.explore-container::-webkit-scrollbar {
  display: none;
}

div.posts-container {
  padding-top: 15px;
}

.explore-button {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  background: none;
}

@media (max-width: 768px) {
  form.login,
  form.register,
  form.contact,
  form.safe-content,
  form.plag-remover,
  form.report {
    margin: 15px;
  }
}
